.counter__description {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	color: white;
	margin-top: 30px;

	div {
		display: flex;
		justify-content: center;
		
		p {
			margin: 0px;
			color: rgba(255, 255, 255, 0.5) !important;
			text-align: center;
		}
	}
}

.bg-cta{
    background-image: url(images/bg-cta.webp);
    background-size: cover;
    position: relative;
    background-position: center center;
}