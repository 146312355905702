//
// responsive.scss
//

@media (min-width: 200px) and (max-width: 991px) { // Mobile
    .hide-for-mobile {
        display: none;
    }

    .show-for-mobile {
        display: block;
    }

    .navbar {
        padding: 0px;
    }

    .navbar-custom {
        margin-top: 0px;
        padding: 15px 0px !important;
        background-color: $white !important;
        box-shadow: 0 10px 33px rgba(0, 0, 0, .1);
        color: $white !important;

        .navbar-nav {
            margin-top: 0px;
            li{
                &.active{
                    border-color: transparent;
                }
                a {
                    transition: all 0.4s;
                    color: $dark;
                    margin: 0px;
                }
            }
        }
        >.container{
            width: 90%;
        }
    }
  
    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: $custom !important;
    }
  
   
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: $dark;
    }

    .navbar-button{
        margin-top: 15px;
        margin-left: 0px;
    }


    .logo{
        width: 150px;

        .logo-light {
            display: none;
        }
        .logo-dark {
            display: inline-block;
        }
    } 

    .home-title{
        font-size: 26px;
    }

    .home-button span {
        display: none !important;
     }

    .about__sports, .about__sportsbooks {
        img {
            width: 50px;
            height: 50px;
        }
    }

    .join-server-button, .donate-button {
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        a {
            color: white !important; 
        }
    }
    
    .global-filters__footer {
        margin-top: 1rem;
    }

    .prop-table-container {
        margin: 1rem 0.5rem !important;
    }
}

@media screen and (min-width: 480px) and (max-width: 1224px) { //TABLET
    .show-for-mobile {
        display: none;
    }

    .hide-for-mobile {
        display: block;
    }
}

@media screen and (min-width: 1224px) and (max-width: 1812px){ //Desktop
    .show-for-mobile {
        display: none;
    }
}

@media screen and (min-width: 1812px) { //Desktop Wide
    .show-for-mobile {
        display: none;
    }
}


