.prop-table-container {
	margin: 2rem;
	max-width: 100%;
}

.prop-table {
	height: 100%;
	min-height: 1px;
}

.prop-table-header {
	text-align: center;
	height: 100%;
}

.prop-table-header.fanduel {
	backgroundColor: black
}

.prop-table-cell {
	background-color: white;
	padding: 0px !important;
	text-align: center;
	height: 100%;
}

.prop-table-cell__div {
	padding: 0.5rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.prop-table__min-filter {
	padding: 2px 8px;
	border-radius: 4px;
	border: 1px solid hsl(0, 0%, 80%);
	background-color: hsl(0, 0%, 100%);
	font-weight: bold;
}


