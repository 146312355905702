//
// helper.scss
//


.section {
    padding-top: 100px;    
    padding-bottom: 100px;
    position: relative;
}

.vertical-content {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.bg-overlay {
    background: url(images/overlay.webp);
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.overlay {
    background-color: #282828;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.f-12 {
    font-size: 12px;
}

.f-14 {
    font-size: 14px;
}

.f-15 {
    font-size: 15px;
}

.f-16 {
    font-size: 16px;
}

.f-17 {
    font-size: 17px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.line-height_1_4 {
    line-height: 1.4;
}

.line-height_1_6 {
    line-height: 1.6;
}

.line-height_1_8 {
    line-height: 1.8;
}

.read-more {
    color: $custom;
    font-weight: 500;
  
    &:hover{
        color: $custom;
        transition: all 0.3s;
    }

    i {
        font-size: 16px;
        position: relative;
        top: 2px;
    }
}

.title-heading {
    font-size: 28px;
}

.title-desc {
    max-width: 600px;
    margin: 0 auto;
}

.join-server-button {
    background-color: #EF5350;
    border-radius: 4px;
    color: white !important;
    font-weight: 400;

    a {
        color: white !important;
    }
}

.donate-button {
    background-color: #FFA726;
    border-radius: 4px;
    color: white !important;
    font-weight: 400;

    a {
        color: white !important;
    }
}


