//
// general.scss
//


body {
    font-family: $font-family-base;
    color: #282828 !important;
    font-size: 1rem;
    background-color: #d6d6d6;
}

.wrapper{
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-secondary;
}

.btn,
.btn:focus,
button,
button:focus {
    outline: none !important;
}

a {
    text-decoration: none !important;
    outline: none;
}

p{
    line-height: 1.8;
}

hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.collapse.show {
    background: transparent;
    margin-top: 1rem;
}