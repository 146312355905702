  //
// features.scss
//



.card{
    border: none;
    background: $white;
}

.collapse {
    justify-content: end;
}

.collapse.show{
    background: transparent;
    justify-content: end;
}

.card-header{
    background-color: transparent;
    border: none;
    h5{
            font-size: 16px;
            color: $dark;
        
    }
}

.accordion .card:last-of-type {
    border-bottom: none;
}

.features__description  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.features-img {
    img {
        border-radius: 3px;
    }
}