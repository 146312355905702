.bg-props {
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 2rem;
}

.prop-container {
	display: flex;
	flex-direction: column;
}