.global-filters {
	margin-top: 3rem;
	padding: 1.3rem;
	border: 1px solid $grey;
	background-color: $peach;
	border-radius: 0.5rem;
}

.global-filters__header {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 1.3rem;
	margin-bottom: 0.5rem;
}

.global-filters__sub-header {
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 0.2rem;
}

.global-filters__icon {
	height: 30px;
	width: 30px;
	border-radius: 0.5rem;
}

.global-filters__options {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;

	label {
		margin-bottom: 0px;
	}
}

.global-filters__options-label {
	display: flex;
	gap: 0.5rem;
}

.global-filters__options-group {
	display: flex;
	gap: 0.5rem;

	span {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.global-filters__statuses {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.global-filters__footer {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	.global-filters__footer-group {
		display: flex;
		justify-content: center;
		gap: 1rem;
	}
}

.global-filters__refresh-button, 
.global-filters__refresh-button:hover, 
.global-filters__refresh-button:disabled {
	background-color: #609f77;
	border: none;
}

.global-filters__faq-button, 
.global-filters__faq-button:hover, 
.global-filters__faq-button:disabled {
	background-color: #AB47BC;
	border: none;
}

.global-filters__download-csv-button, 
.global-filters__download-csv-button:hover, 
.global-filters__download-csv-button:disabled {
	background-color: #2F97C1;
	border: none;
	color: white;
}



