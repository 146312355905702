//
// home.scss
//


.bg-home {
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding-top: 100px;
    min-height: 100vh;
}

.home-center {
    display: table;
    width: 100%;
    height: 100%;
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle;
}

.home-content {
    position: relative;
    z-index: 9;
}

.home-title{
    font-size: 42px;
    color: $black;
    max-width: 630px;
    margin: 0 auto;
}

.home-button {
    span {
        position: relative;
        top: 8px;
        color: $white;
    }
}


// home-2


.home-title-3{
    font-size: 36px;
    color: $white;
    margin: 0 auto;
}

// home-3


.slidero {
    position: relative;
    z-index: 1;
}


// home-4
.bg-home-4 {
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    height: 100vh;
}


// home-5
.bg-home-5{
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 250px 0px 250px 0px;
}


// home-5
.subscribe input {
    padding: 14px 20px;
    width: 100%;
    font-size: 18px;
    color: $dark !important;
    border: none;
    outline: none !important;
    padding-right: 180px;
    padding-left: 30px;
    background-color: $white;
    border-radius: 30px;
}

.subscribe button {
    position: absolute;
    top: 5px;
    right: 6px;
    outline: none !important;
    border-radius: 30px;
    font-size: 17px;
    padding: 9px 30px;
}

.subscribe form {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
}

// home-6
.bg-home-6{
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 270px 0px 270px 0px;
}

.home-title-6{
    font-size: 36px;
    color: $white;
}

// home-7
.bg-home-7 {
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 270px 0px 270px 0px;
}

/* flexdemo */

.flex-control-paging li a {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    box-shadow: 0 0 0 0 $white;
    height: 12px;
    width: 12px;
    opacity: 1;
    border: 2px solid $white !important;
    background: $white;
}

.flex-control-paging li a:hover {
    background: $white;
}

.main-slider .flex-control-paging li a.flex-active {
    background-color: transparent;
}

.flex-control-nav {
    bottom: -70px;
}

// home-8
.bg-home-8 {
    background: $primary;
    background-size: cover;
    background-position: center center;
    position: relative;
    padding: 250px 0px 250px 0px;
}

// home-9
.home-title-9{
        font-size: 46px;
        color: $white;
        max-width: 500px;
     
}

.home-registration-form {
    border-radius: 3px;
    box-shadow: 0px 0px 3px 0px #d2d2d2;
    width: 600px;
}

.home-registration-form .registration-form label {
    font-size: 13px;
}

.registration-form .registration-input-box {
    border: none;
    border-radius: 5px;
    color: #999;
    height: 45px;
    border: 1px solid #e0e0e0 !important;
    box-shadow: none !important;
}

.home-img {
    img {
        border-radius: 3px;
    }
}