.about__sports, .about__sportsbooks {
	margin-top: 15px;
	display: flex;
	justify-content: center;
	gap: 20px;

	img {
		height: 100px;
		border-radius: 10px;
		text-align: center;
	}

	p {
		text-align: center;
		word-wrap: break-word;
	}
}

.about__sports {
	img {
		width: 120px;
	}
}

.about__sportsbooks {
	img {
		width: 100px;
	}
}