  
  //
// components.scss
//

  
.bg-custom {
    background-color: $custom;
}

.bg-light{
    background-color: $light !important;
}

.text-custom {
    color: $custom !important;
}

.text-muted {
    color: $muted !important;
}

.btn {
    padding: 12px 24px;
    font-size: 1rem;
    letter-spacing: 0.5px;
    &:focus{
        box-shadow: none;
    }
}

.btn-sm {
    padding: 10px 20px;
}

.btn-round {
    border-radius: 4px !important;
}

.btn-rounded{
    border-radius: 30px !important;
}

.btn-custom {
    color:$white;
    border-radius: 0px;
    background-color: $custom;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.btn-custom.focus,
.btn-custom:active,
.open>.dropdown-toggle .btn-custom {
    color: $white;
    background-color: $custom;
    
}


.btn-white {
    color:$dark;
    background-color: $white;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.focus,
.btn-white:active,
.open>.dropdown-toggle .btn-white {
    color: $dark;
    background-color: $white;
    
}


.btn-outline {
    color: $custom;
    border: 1px solid $custom;
    border-radius: 0px;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active,
.btn-outline.active,
.btn-outline.focus,
.btn-outline:active,
.btn-outline:focus,
.btn-outline:hover,
.open>.dropdown-toggle .btn-outline {
    outline: none;
    border: 1px solid $custom;
    background-color: $custom;
    color: $white;

}